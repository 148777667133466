<template>
    <v-container v-if="show">
        <v-row no-gutters>
          <v-simple-table id="recent-campaigns">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Registered On
                  </th>
                  <th class="text-left">
                    User Status
                  </th>
                  <th class="text-left">
                    Role
                  </th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in UsersData"
                  :key="item.Username"
                >
                <template v-if="!gettingUsersInProgress">
                    <td>
                        <span v-if="item['Attributes'].find(e => e['Name'] == 'given_name') != undefined">{{item['Attributes'].find(e => e['Name'] == 'given_name')['Value']}}</span>
                    </td>
                    <td>{{item['Attributes'].find(e => e['Name'] == 'email')['Value']}}</td>
                    <td>{{ getMonth(item.UserCreateDate) +'/'+ new Date(item.UserCreateDate).getDate() +'/'+ new Date(item.UserCreateDate).getFullYear() }}</td>
                    <td>{{item.UserStatus}}</td>
                    <td><span v-if="item['Attributes'].find(e => e['Name'] == 'custom:role')">{{item['Attributes'].find(e => e['Name'] == 'custom:role')['Value']}}</span></td>
                    <td class="more-options text-center">
                      <v-icon @click="openUpdateUserBox('Update', item)" :class="'disabled-'+userActionBtnDisabled">edit</v-icon>
                      <a @click="deleteUser(item)" :class="'disabled-'+userActionBtnDisabled">
                        <v-progress-circular
                            indeterminate
                            color="#777BD2"
                            v-if="selectedID == item.Username"
                        ></v-progress-circular>
                        <v-icon v-if="selectedID != item.Username" class="deleteBtn">delete</v-icon>
                      </a>
                      <transition name="fade-transition"><confirmation-dialog v-if="dialogId == item.Username" actionName="deleteUser" primaryTxt="Delete" :item="item" @close="cancel()"></confirmation-dialog></transition>
                    </td>
                </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <template>
            <v-progress-circular
                indeterminate
                color="#777BD2"
                v-if="gettingUsersInProgress"
                class="progressLoader"
            ></v-progress-circular>
          </template>
        </v-row>
    </v-container>
</template>

<script>
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
export default {
  name: "UserManagement",
  components: {
    ConfirmationDialog
  },
  data(){
    return{
      userEmail: '',
      user: '',
      show: false
    };
  },
  mounted(){
    if(this.$store.state.user == null || this.$store.state.user == undefined){
      this.$router.push({path: '/sign-in'});
    }
    else{
      if(this.$store.state.user.attributes['custom:role'] != 'Admin'){
        this.$router.push({path: '/'});
      }
      else{
        this.userEmail = this.$store.state.user.attributes.email;
        this.user = this.$store.state.user.attributes;
        this.show = true;
      }
    }
  },
  methods:{
    showUsers(){
      this.$store.state.gettingUsersInProgress = true
      this.$store.dispatch('getAllUsers');
    },
    getMonth(time){
      var month = new Date(time).getMonth() + 1;
      if(month<10){
        month = '0'+month;
      }
      return month;
    },
    deleteUser(item){
      this.$store.state.userActionBtnDisabled = true;
      this.$store.state.dialogId = item.Username;
    },
    openUpdateUserBox(param, user){
      this.$store.state.updateUserData = user;
      if(param == 'add'){
        this.$store.state.add_user_primary_btn_txt = 'Add';
        this.$store.state.userHd = 'Add New User';
      }
      else{
        this.$store.state.add_user_primary_btn_txt = 'Update';
        this.$store.state.userHd = 'Edit User';
      }
      this.$store.state.addUserBox = true;
    },
    cancel(){
      this.$store.state.dialogId = '';
      this.$store.state.userActionBtnDisabled = false;
    }
  },
  computed: {
    UsersData(){
      var x = '';
      if(this.$store.state.allUsers == undefined){
        this.showUsers();
      }
      if(this.$store.state.allUsers){
        x = this.$store.state.allUsers.data.Users
      }
      return x;
    },
    gettingUsersInProgress(){
      return this.$store.state.gettingUsersInProgress;
    },
    currentUser(){
      return this.$store.state.user;
    },
    dialogId:{
      get(){
        return this.$store.state.dialogId;
      },
      set(value){
        this.$store.state.dialogId = value
      }
    },
    selectedID(){
      return this.$store.state.selectedID
    },
    userActionBtnDisabled(){
      return this.$store.state.userActionBtnDisabled
    },
  },
  watch: {
    /* campaignsData (newCount) {
      // Our fancy notification (2).
      console.log(`We have ${newCount} fruits now, yay!`)
    } */
  },
  created(){
    
  }
};
</script>
<style scoped>
#recent-campaigns{
  width: 100%;
}
tr td{
    cursor: pointer;
}
tr td:last-child{
    cursor: auto;
}
#recent-campaigns .more-options{
  display: inline-block;
  font-size: 25px !important;
  width: 100%;
  /* background: none;
  box-shadow: none;
  padding: 0;
  height: fit-content;
  min-width: max-content; */
}
#recent-campaigns thead{
  background-color: #CDEAFF;
}
#recent-campaigns thead th{
  font-size: 18px;
  font-weight: 400;
}
#recent-campaigns tbody td{
  font-size: 16px;
  font-weight: 400;
}
.progressLoader.v-progress-circular{
  height: 100px !important;
  width: 100px !important;
  margin: 15px auto;
}
.more-options button:first-child, .more-options a:first-child{
  margin-right: 5px;
}
.more-options button, .more-options a{
  opacity: 0.6;
}
.more-options button:hover, .more-options a:hover{
  opacity: 1;
}
a.disabled-false{
  pointer-events: auto;
}
a.disabled-true{
  pointer-events: none;
}

@media screen and (max-width: 850px){
  #recent-campaigns .more-options{
    padding: 2px;
  }
}
</style>
